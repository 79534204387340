import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function AboutHero2() {
  const data = useStaticQuery(graphql`
    query AboutHero2Query {
      file(relativePath: { eq: "collage.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <div className="bg-gray-50 overflow-hidden">
      <div className="relative max-w-7xl mx-auto pb-16 pt-12 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
        <div className="mx-auto text-base max-w-prose lg:max-w-none">
          <h1 className="text-base  text-gray-500 font-semibold tracking-wide uppercase">
            About Kristi Adams
          </h1>
          <h2 className="mt-2 mb-8 text-4xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            <em className="text-london-darkred font-black italic text-5xl">
              Who
            </em>{" "}
            works for you matters!
          </h2>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-300"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div
              aria-hidden="true"
              className="relative text-base mx-auto max-w-prose lg:max-w-none"
            >
              <figure>
                <div className="relative lg:pb-0">
                  <Img
                    className="rounded-lg shadow-lg object-cover object-center absolute inset-0 w-full h-full md:w-3/4 md:h-3/4 lg:static lg:h-auto"
                    fluid={data.file.childImageSharp.fluid}
                    alt="Kristi Adams with happy clients."
                  />
                </div>
                <figcaption className="flex mt-3 text-sm text-gray-500">
                  Kristi Adams
                </figcaption>
              </figure>
            </div>
          </div>
          <div>
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg leading-7 font-bold text-gray-500 mb-5">
                Kristi is a conscientious Realtor whose credibility, commitment,
                and attention to detail are her clients' greatest resources. Her
                excellent communication skills and warm friendly approach give
                her clients the peace of mind they deserve.
              </p>
            </div>
            <div className="prose text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <blockquote>
                <p>
                  I am truly invested in this community and my passion for it is
                  an asset to both the buyer and seller. As a Kingsburg
                  resident, I get to participate in its wonderful transformation
                  – a revived and bustling downtown with that small-town feel,
                  the restoration of timeless neighborhoods and all the new
                  modern family homes this town is known for. I am so proud and
                  excited by the growth and opportunity this area offers.
                </p>
              </blockquote>
              <p>
                Part of a 4th generation Valley farming family, she is
                well-connected and actively involved in the community. As a mom
                with children in elementary and High School, Kristi appreciates
                the family focus the area provides. As a former corporate
                recruiter, she leverages her business savvy and experience
                realizing the financial goals of her clients.
              </p>

              <h2>How she can help</h2>
              <p>
                Whether it's navigating the way for first timers, finding that
                Dream Home, or locating the perfect income property, Kristi is
                here to guide you through the process and make those aspirations
                a Real Estate reality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
