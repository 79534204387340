import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function Testimonial() {
  const data = useStaticQuery(graphql`
    query TestimonialImageQuery {
      file(relativePath: { eq: "joy_testimonial.jpeg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <section className="py-6 bg-gray-100 overflow-hidden md:py-10 lg:py-12">
      <h2 className="hidden">Testimonial</h2>
      <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
          width="404"
          height="404"
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="joy-testimonial"
        >
          <title id="joy-testimonial">Joy Thornhill Testimonial</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="404"
            fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
          />
        </svg>

        <div className="relative">
          <blockquote className="mt-8">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
              <p>
                &ldquo;Kristi was just phenomenal! Moving from out of the area
                was such a daunting task. I was just so grateful for her help
                and guidance.&rdquo;
              </p>
            </div>
            <div className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <Img
                    className="mx-auto h-12 w-12 rounded-full"
                    fluid={data.file.childImageSharp.fluid}
                    alt="Joy Thornhill, new Central Valley homeowner."
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base leading-6 font-medium text-gray-900">
                    Joy Thornhill
                  </div>

                  <span className="hidden md:block mx-1 h-5 w-5 text-gray-300">
                    &#8226;
                  </span>

                  <div className="text-base leading-6 font-medium text-gray-600">
                    new homeowner
                  </div>
                </div>
              </div>
            </div>
          </blockquote>
        </div>
      </div>
    </section>
  )
}
