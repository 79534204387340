import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

export default function Bottom({ tag }) {
  const data = useStaticQuery(graphql`
    query BottomImageQuery {
      file(relativePath: { eq: "headShot_1920x1080.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    // Use section tag?
    <section className="relative  bg-london-navyblue">
      <div className="h-56  sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <Img
          className="w-full h-full object-cover"
          fluid={data.file.childImageSharp.fluid}
          alt="Kristi Adams wants to help. Contact her now."
        />
      </div>
      <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <div className="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
            {tag}
          </div>
          <h2 className="mt-2 text-white text-3xl leading-9 font-bold tracking-tight sm:text-4xl sm:leading-10">
            Hi, I'm Kristi!
          </h2>
          <p className="mt-3 text-lg leading-7 text-gray-300">
            Thinking of buying or selling a home? Let me connect you with others
            in the market!
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/contact"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
