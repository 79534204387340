import React from "react"
import Layout from "../components/Layout"
import AboutHero2 from "../components/AboutHero2"
import Bottom from "../components/Bottom"
import SEO from "../components/Seo"
import Testimonial from "../components/Testimonial"

export default function About({ location }) {
  return (
    <Layout>
      <SEO title={"About"} pathname={location.pathname} />
      <AboutHero2 />
      <Testimonial />
      <Bottom tag="Here to help" />
    </Layout>
  )
}
